<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        permission="estrutura_grupos"
        newPath="/grupos/new"
        editPath="/grupos/"
        :expander="false"
        subtitle="Grupo"
        tooltip="Cadastro agrupador de Clientes"
        nomeTelaDoManual="grupos-list"
        origemHistoricoAcao="/grupos"
    >
        <template #columns>
            <Column field="id" header="Cod." :sortable="true" sortField="id"></Column>
            <Column field="descricao" header="Descrição" :sortable="true" sortField="descricao"></Column>
            <Column field="createdAt" header="Criado" :sortable="true" sortField="created_at">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                </template>
            </Column>
            <Column field="updatedAt" header="Alterado" :sortable="true" sortField="updated_at">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';

export default {
    data() {
        return {
            tenantCustomers: [],
            service: null
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/grupos');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        }
    }
};
</script>
